<template>
  <div class="index" >
    <img src="@/assets/bg-3.png" alt="" />
    <div class="box">
      <img src="@/assets/360.png" alt="" @click="$setRead(3, 1)" />
    </div>
    <img class="title-img" src="@/assets/title3-1.png" alt="" />
    <div class="box">
      <img src="@/assets/video.png" alt="" />
      <div class="video">
        <m-video-player
          ref="video2"
          src="https://zk.ohedu.cn/upload/video/filename/16739/SH/master.m3u8"
          name="3"
          @play="$setRead(3, 2);$refs.video3.$refs.videoPlayer.player.pause(1)"
        >
        </m-video-player>
      </div>
    </div>
    <img class="title-img" src="@/assets/title3-2.png" alt="" />
    <div class="wrap" @click="$setRead(3, 3)">
      <m-video-player
        ref="video3"
        src="https://zk.ohedu.cn/upload/video/filename/16951/SH/master.m3u8"
        name="game"
        @play="$setRead(3, 3);$refs.video2.$refs.videoPlayer.player.pause(1)"
      />
    
      <p class="wrap-content">
        丰收的季节到了，“小农夫们”准备搬运成熟的南瓜。南瓜沉重，可把他们累得气喘吁吁。这时，他们发现可以借助扁担这个工具，就能一次性轻松地搬运比较多的南瓜。他们还玩起了挑瓜比赛的游戏呢！
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "App",
  components: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
  
  },
  watch: {},
};
</script>
  
<style lang="less" scoped>
.farm-list {
  top: 20px;
  overflow: hidden;

  > div {
    width: 225px;
    margin: 0;
    overflow: visible;
  }

  .farm {
    width: 210px;
    height: 230px;
    background: #ffffff;
    border-radius: 8px;
    margin: 0 0 0 15px;
    text-align: center;

    .farm-name {
      font-size: 17px;
      font-weight: bold;
      padding: 10px 0 10px;
    }

    img {
      width: 140px;
      height: 140px;
      border-radius: 50%;
    }

    .farm-more {
      width: 80px;
      height: 26px;
      background-image: linear-gradient(180deg, #40b178 0%, #1e7943 100%);
      border-radius: 16px;
      line-height: 26px;
      color: #ffffff;
      margin: auto;
      margin-top: 10px;
      font-size: 13px;
    }
  }
}
</style>